.App {
  text-align: center;
  background-color: #b3bfd6;
}

.App-body {
  background-color: #b3bfd6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgb(37, 37, 37);
  margin-top: 20px;
}

.App-link {
  color: #61dafb;
}


